/*******************************
         Site Overrides
*******************************/
.ui.segment.basic.page-header {
  padding: 0;
  margin-left: auto;
  margin-right: auto;

  &.registries__header {
    width: @registriesPageWidth;
    height: @registriesHeaderHeight;
    margin-top: 3.5625rem;
    margin-bottom: 3.75rem;

    .ui.header {
      font-size: @registriesHeaderFontSize;
      color: #050d29;
      font-weight: 700;
      line-height: 2.5rem;
      height: 2.5rem;
    }
  }
}
