/*******************************
         Site Overrides
*******************************/
html {
  background: @gradientTwo;
}

body {
  min-height: 100%;
  height: auto;
}
/** shared classes **/
.with-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.rtl-text {
    direction: rtl;
    text-align: left;
  }
}
/** logos, images **/
/**  mocks**/
.mock-label-one {
  width: 10.8125rem;
}

.mock-label-two {
  width: 9.625rem;
}

.mock-label-three {
  width: 3.625rem;
}

.mock-label-one, .mock-label-two, .mock-label-three {
  border-radius: 0.125rem;
  height: 0.5rem;
  background-color: @darkBlue;
  opacity: 0.1;
}

/** PAGES STYLES **/

.firstContnentElem {
  margin-top: 0 !important;
  padding-top: 4.75rem !important;

  @media screen and (min-height: 900px) {
    padding-top: 7.5rem !important;
  }
  @media screen and (min-height: 1050px) {
    padding-top: 10rem !important;
  }
  @media screen and (min-height: 1200px) {
    padding-top: 12.5rem !important;
  }

  @media screen and (min-height: 1440px) {
    padding-top: 15rem !important;
  }
}

/** REGISTRIES **/
.registries {
  width: @registriesPageWidth;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .registry {
    width: 16.75rem;
    height: 14.125rem;
    border-radius: @defaultBorderRadius;
    border: 1px solid #d4e2f1;
    background-color: #ffffff;
    margin: 0.7rem;

    &-main {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.25rem;
      height: 9.4375rem;

      &-logo {
        width: 5.375rem;
        height: 5.375rem;
        margin-bottom: 1rem;
      }

      &-title {
        height: 1.1875rem;
        color: #000000;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.01rem;
      }
    }
    &-ctrls {
      text-align: center;
    }
  }
}


#log-container {
  .xterm-viewport {
    height: 100% !important;
  }
}
