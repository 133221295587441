/*******************************
        User Overrides
*******************************/

.ui.selection.dropdown {
  &:active, &:focus {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  div.visible.menu.transition {
    margin-top: 0.375rem !important;
    padding-top: 0.375rem !important;
    padding-bottom: 0.375rem !important;
    border-top-width: 1px !important;
    border: 1px solid #d4e2f1 !important;
    border-radius: 0.375rem !important;

    >.item {
      width: 12.375rem;
      height: 2.125rem !important;
      margin-right: 0.375rem;
      margin-left: 0.375rem;
      margin-top: 0.125rem;
      border-radius: 0.25rem;
      padding-top: 0.5625rem !important;
      padding-left: 0.625rem !important;
      padding-bottom: 0.5625rem !important;

      &:hover {
        width: 12.375rem !important;
        height: 2.125rem !important;
        border-radius: 0.25rem !important;
        background-color: #f7fbff !important;
      }

      &.selected {
        background-color: #f7fbff !important;
        background: url("../../../assets/icons/icon.checked.svg") no-repeat;
        background-position: 92% 50%;
        height: 2.125rem !important;
      }

      &:active, &:focus, &.active {
        height: 2.125rem !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
        letter-spacing: 0.01rem !important;
        line-height: 1.375rem !important;
      }
    }
  }
}