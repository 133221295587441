/*******************************
         Site Overrides
*******************************/
/*TEXT INPUT -- START*/

.ui.input > input[type="text"] {
  height: 2.875rem;
  color: #000;
  font-weight: 400;
  line-header: 1.375rem;
  border-radius: @defaultBorderRadius;
}

.ui.input.error > input {
  background-color: @white;
  border: 1px solid @error;
}

.ui.input.focus > input, .ui.input > input:focus {
  border: 1px solid #0c5ce5 !important;
}

.ui[class*="right labeled"].input > input:focus {
  border-right-color: #0c5ce5 !important;
}

.ui.error > input:focus {
  border: 1px solid #ff3232 !important;
}

.ui[class*="right labeled"].input.error > input:focus {
  border-right-color: transparent !important;
}

/*TEXT INPUT -- END */

/* TEXTAREA -- START */
textarea {
  border-radius: @defaultBorderRadius;
  line-height: 1.375rem;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
}