/*******************************
         Site Overrides
*******************************/
/** Primary action **/
.ui.button.btn-one {
  background: linear-gradient(180deg, #0C5CE5 0%, #0853D3 100%);
  color: @white;
  border-radius: @defaultBorderRadius;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &:hover::before {
  opacity: 1;
  }
  &:active::before {
  opacity: 1;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.596664), rgba(0, 0, 0, 0.596664)), @gradientOne;
  }
  &.disabled {
    background-image: @gradientOne !important;
    opacity: 0.4 !important;
  }

  &::before {
    position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, #073789 0%, #05327F 100%);
      transition: all 240ms ease;
      opacity: 0;
      z-index: -1;
  }
}

/** Use only for ‘Pull and Deploy’ button **/
.ui.button.btn-two {
  color: @darkBlue;
  background: @white;
  border: @btnBorder;
  box-shadow: 0px 2px 3px rgba(16, 67, 120, 0.05);
  border-radius: @defaultBorderRadius;
  margin: 0;
  padding: 0;

  &:hover {
    color: @white;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.396664), rgba(0, 0, 0, 0.396664)), @gradientOne;
  }
  &:active {
    color: @white;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.596664), rgba(0, 0, 0, 0.596664)), @gradientOne;
  }
  &-disabled {
    margin: 0;
    padding: 0;
    background-image: @gradientOne;
    color: @white;
    opacity: 0.4;
  }
}

/** Secondary action, link **/
.ui.button.btn-three {
  color: @darkBlue;
  background: @white;
  border: @btnBorder;
  border-radius: @defaultBorderRadius;
  box-shadow: 0px 2px 3px rgba(16, 67, 120, 0.05);
  margin: 0;
  padding: 0;

  &:hover {
    box-shadow: 0px 4px 8px rgba(16, 67, 120, 0.1);
  }
  &:active {
    background: #F7FBFF;
    box-shadow: 0px 2px 3px rgba(16, 67, 120, 0.05);
  }
  &-disabled {
    box-shadow: 0px 2px 3px rgba(16, 67, 120, 0.05);
    opacity: 0.4;
  }
}

/** Navigation primary **/
.ui.menu > a.item.btn-four {
  color: @black;
  background: transparent;
  border-radius: @defaultBorderRadius;

  &:hover {
   background: rgba(228, 238, 254, 0.4);
  }
  &:active {
    background: rgba(228, 238, 254, 1);
  }
  &-disabled {
    opacity: 0.4;
  }
}
/** Navigation secondary (tabs) **/
a.item.btn-five {
  background: transparent;
  color: @black;

  &:hover {
    border-bottom: 1px solid rgba(0, 166, 255, 0.2);
  }
  &:active {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
  }
  &-disabled {
    opacity: 0.4;
  }
}

/** Destructive action primary **/
.ui.button.btn-six {
  background: linear-gradient(360deg, #FF4517 0%, #FF5F3A 100%);
  border-radius: @defaultBorderRadius;
  margin: 0;
  padding: 0;
  color: @white;

  &:hover {
    background: linear-gradient(360deg, #FF4517 0%, #FF5F3A 100%);
    box-shadow: 0px 4px 8px rgba(16, 67, 120, 0.1);
  }
  &:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(360deg, #FF4517 0%, #FF5F3A 100%);
  }
  &.disabled {
    background: linear-gradient(360deg, #FF4517 0%, #FF5F3A 100%) !important;
    opacity: 0.4 !important;
  }
}
/** Destructive action secondary **/
.ui.button.btn-seven {
  background: @white;
  border: @borderWidth solid #FF3232;
  border-radius: @defaultBorderRadius;
  color: #ff3232;
  margin: 0;
  padding: 0;

  &:hover {
    color: @white;
    background: linear-gradient(360deg, #FF4517 0%, #FF5F3A 100%);
  }
  &:active {
    color: @white;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), linear-gradient(360deg, #FF4517 0%, #FF5F3A 100%);
  }
  &-disabled {
    opacity: 0.4;
  }
}

.ui.button.deploy-cluster-btn {
    height: 2.5rem;
    width: 12.625rem;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.01rem;
    padding: .625rem 1rem 0.6875rem 1rem;
}

.ui.button.pull-deploy-btn {
    min-width: 8.125rem;
    width: 8.125rem;
    height: 2rem;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: 0.00875rem;
}

.ui.button.deploy-cluster-btn-table {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.00875rem;
  width: 11.25rem;
  height: 2rem;
  padding: 0;

  >span {
    margin-right: .6875rem;
  }
}

.ui.button.create-registry-btn {
  width: 11.125rem;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.01rem;
  float: right;
}

.ui.button.registry-ctrls {
  &-edit, &-remove {
    width: 7rem;
    height: 2.5rem;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.01rem;
  }

  &-edit {
    margin-right: 0.875rem;
  }
}

.ui.button.btn-login {
  width: 8rem;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.01rem;
}

.ui.button.btn-create-account {
  width: 10.9375rem;
  height: 2.5rem;
  color: @white;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.01rem;
}

.ui.button.inviteMemberBtn {
  position: absolute;
}

.ui.button.btn-create-account.btn-create-account_mobile {
  display: none;
}
