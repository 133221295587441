/*******************************
         Site Overrides
*******************************/

.ui.progress > .bar {
  min-width: 0;
}

.ui.progress {
  background: #eaf6ff;
  margin: 0 !important;

  &.green {
    >div.bar {
      background: #12d4ad;
    }
  }
  &.orange {
    >div.bar {
      background: #ff9d2b;
    }
  }
  &.red {
    >div.bar {
      background: linear-gradient(to top, #ff4517 0%, #ff5f3a 100%);
    }
  }
}