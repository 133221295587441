/*******************************
         Site Overrides
*******************************/

@media screen and (min-width: 1920px) {
  .nav-menu {
    .ui.menu {
      width: 1360px !important;
    }
  }
}

.nav-menu {
  position: relative;
  background: @white;
  box-shadow: 0 1px 0 #e8f3ff;

  >.logo-main {
    left: 2.5rem;
    top: 1.3125rem;
    position: absolute;
    height: 1.75rem;
    width: 2.5625rem;
    cursor: pointer;
  }

  .ui.menu {
    width: 1200px !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0 !important;

    height: 4.375rem;
    background: @menuBackground;
    align-items: center;
    justify-content: center;
    box-shadow: @menuBoxShadow;;
    border-bottom: 0;
    border: 0;
    border-radius: 0;

    .item.nav-menu-item {
      height: 2.5rem;
      font-weight: 600;
      padding: 0.6875rem 1.125rem 0.625rem 1.125rem;
      font-size: 1rem;
      text-align: center;
      letter-spacing: 0.01rem;

      >span.nav-menu-item-icon {
        margin-right: 0.75rem;
      }
    }
  }

  .active.item.nav-menu-item.btn-four {
    background-color: @menuItemActiveBG;

    &:hover {
      background: @menuItemActiveBG;
    }
  }
}
